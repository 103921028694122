import { defineCustomElement } from 'vue';
import ReplyAgentWidget from '@/components/ReplyAgentWidget.vue';
customElements.define('replyagent-widget', defineCustomElement(ReplyAgentWidget));
customElements.define('ra-widget', defineCustomElement(ReplyAgentWidget));

var style = document.createElement('style')
style.innerHTML = '.ra_w_bubble,.ra_w_header{background-color:var(--raw-header-bg)}.ra_w_bubble{width:2.5rem;height:2.5rem;position:fixed;bottom:1.5em;z-index:99;border-radius:100%;padding:.85rem;box-shadow:0 0 10px -1px #00000380}.ra_w_bubble,.ra_w_bubble img{user-select:none}.ra_w_container{border-top-left-radius:var(--raw-radius);border-top-right-radius:var(--raw-radius);position:fixed;bottom:8rem;font-family:var(--raw-font-family);z-index:99}.ra_w_body,.ra_w_header{padding:var(--raw-padding);border:1px solid var(--raw-border-color)}.ra_w_header{border-top-left-radius:var(--raw-radius);border-top-right-radius:var(--raw-radius);font-size:var(--raw-header-size);color:var(--text-white)}.ra_w_body{padding:var(--raw-padding);background-color:var(--raw-body-bg);border-bottom-left-radius:var(--raw-radius);border-bottom-right-radius:var(--raw-radius);padding:1rem}.ra_w_c{display:flex;flex-wrap:wrap;justify-content:space-evenly}.ra_w_c .ra_channel .ra_icon{background-color:#fff;padding:.3rem;border-radius:.5rem}.ra_w_container .ra_footer{font-size:.75em}.bounce-enter-active{animation:.5s bounce-in}.bounce-leave-active{animation:.25s reverse bounce-in}@keyframes bounce-in{0%{transform:scale(0)}50%{transform:scale(1.25)}100%{transform:scale(1)}}.ra_w_bubble_closed{transition:transform .5s ease-in-out;transform:rotate(0)}.ra_w_bubble_active{transition:transform .5s ease-in-out;transform:rotate(180deg)}@media only screen and (max-width:600px){.ra_w_container{width:96%}.ra_w_right{right:2%}.ra_w_left{left:2%}}@media only screen and (min-width:600px){.ra_w_container{min-width:25em;width:25em}.ra_w_right{right:1.5em}.ra_w_left{left:1.5em}}';

var host = document.getElementById('ReplyAgentWidget');
if(!host){
    host = document.getElementById('raw');
}

if(host){
    host.shadowRoot.appendChild(style)
}